import posthog, { type PostHog, type Properties } from 'posthog-js';
import { PostHog as PostHogNode } from 'posthog-node';

export const FEATURE_KEYS = {
    TREASURY: 'show_treasury',
    GOVERNANCE: 'governance',
    LEADERBOARD: 'leaderboard',
    OFFICE_HOURS: 'office_hours',
    ACCOUNT: 'accounts',
    COMMUNITY: 'community',
    SECURITY_AND_PRIVACY: 'security_and_privacy',
    MEMBER_STATS: 'member_stats',
    DISPLAY_EXPERTISE_SETTINGS: 'display_expertise_settings',
};

interface TrackEventOptions {
    event: string;
    properties?: Properties;
}

export function trackEvent({ event, properties }: TrackEventOptions) {
    if (typeof window !== 'undefined') {
        posthog.capture(event, properties);
    }
}

export function isFeatureEnabled(featureKey: string) {
    if (typeof window !== 'undefined') {
        return posthog.isFeatureEnabled(featureKey);
    }
}

export function initializePosthogClient(key: string, host: string) {
    posthog.init(key, {
        api_host: host || 'https://app.posthog.com',
        // Disable in development
        loaded: (posthog: PostHog) => {
            if (process.env.NODE_ENV === 'development')
                posthog.opt_out_capturing();
        },
    });
}

export default function PostHogClient() {
    const posthogClient = new PostHogNode(process.env.POSTHOG_KEY, {
        host: process.env.POSTHOG_HOST || 'https://app.posthog.com',
    });
    return posthogClient;
}

export async function getFlags(userId: string = '') {
    const posthog = PostHogClient();
    const flags = await posthog.getAllFlags(userId);
    return flags;
}
